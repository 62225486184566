




































































import { Component, Mixins } from 'vue-property-decorator';
import GroupComponent from '@/mixins/group-component';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { ThingsTemplateEntityModel, ThingsTemplateQueryModel } from '@/entity-model/things-template-entity';
import ThingsTemplateService from '@/service/things-template';
import ThingsTemplateDetailDrawer from './things-template-detail-drawer.vue';
import ThingsSyncDialog from './things-sync-dialog.vue';
import { SyncType, ThingsEntityType } from '@/model/enum';

@Component({
    components: {
        'things-template-detail-drawer': ThingsTemplateDetailDrawer,
        'things-sync-dialog': ThingsSyncDialog
    }
})
export default class ThingsTemplateListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    ThingsTemplateEntityModel = ThingsTemplateEntityModel;

    created() {
        this.initTable({
            service: ThingsTemplateService,
            queryModel: new ThingsTemplateQueryModel(),
            tableColumns: ThingsTemplateEntityModel.getTableColumns()
        });
    }

    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as ThingsTemplateQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`/dashboard/things-template-list?groupId=${groupId}`);
        } else {
            this.$router.push('/dashboard/things-template-list');
        }
    }
    detailClick(model: ThingsTemplateEntityModel) {
        (this.$refs.thingsTemplateDetailDrawer as ThingsTemplateDetailDrawer).drawerShow(model);
    }

    upSysncClick() {
        (this.$refs.thingsSyncDialog as ThingsSyncDialog).dialogOpen(SyncType.UPLOAD, ThingsEntityType.TEMPLATE);
    }

    downSysncClick() {
        (this.$refs.thingsSyncDialog as ThingsSyncDialog).dialogOpen(SyncType.DOWNLOAD, ThingsEntityType.TEMPLATE);
    }
}

